import {QuestionModel} from "@/store/models/bank/CompareProductAreaModel";
import {Constants} from "@/core/config/constants";
import CircleChartModel from "@/store/models/CircleChartModel";
import {
    CustomQuestionsAnalyticsContainer
} from "@/store/models/shared/custom-questions-analytics/CustomQuestionsAnalyticsContainer";

export class BankProductAreaOverviewContainer {
    allQuestions: QuestionModel[];
    customersHighestList: CustomersListModal[];
    customersLowestList: CustomersListModal[];
    customersCount: number;
    respondentsCount: number;
    followed: object;
    high: object;
    low: object;
    productArea: object;
    customQuestionsSectionData: CustomQuestionsAnalyticsContainer = new CustomQuestionsAnalyticsContainer();

    constructor(obj) {
        this.allQuestions = obj.questions ? obj.questions.map(q => new QuestionModel({
            ...q,
            ...q.stats
        })) : [];
        // this.customersHighestList = obj.businessStats ? this.sortCompany(obj.businessStats.map(c => new CustomersListModal(c) ), 'high') : [];
        // this.customersLowestList = obj.businessStats ? this.sortCompany(obj.businessStats.map(c => new CustomersListModal(c) ), 'low') : [];
        this.customersHighestList = obj.businessStats && obj.businessStats.high ? obj.businessStats.high.map(c => new CustomersListModal(c)) : [];
        this.customersLowestList = obj.businessStats && obj.businessStats.low ? obj.businessStats.low.map(c => new CustomersListModal(c)) : [];
        this.customersCount = obj.businesses ? obj.businesses : 0;
        this.respondentsCount = obj.respondents ? obj.respondents : 0;
        this.followed = obj.followed;
        this.high = obj.high;
        this.low = obj.low;
        this.productArea = obj ? new CircleChartModel(obj) : {};

        if (obj.customQuestions) {
            this.customQuestionsSectionData.setCustomQuestionsAnalyticsData(obj.customQuestions);
        }
    }

    sortCompany(data: CustomersListModal[], type) {
        return type === 'high'
            ? data.sort((q1: CustomersListModal, q2: CustomersListModal) => +q2.score - +q1.score)
            : data.sort((q1: CustomersListModal, q2: CustomersListModal) => +q1.score - +q2.score);
    }

    async sortQuestionsBy(what: string | null): Promise<QuestionModel[]> {
        switch (what) {
            case 'highest_score':
                this.allQuestions = this.allQuestions.sort((q1: QuestionModel, q2: QuestionModel) => parseFloat(q2.score) - parseFloat(q1.score));
                break;
            case 'lowest_score':
                this.allQuestions = this.allQuestions.sort((q1: QuestionModel, q2: QuestionModel) => parseFloat(q1.score) - parseFloat(q2.score));
                break;
            case 'highest_importance_score':
                this.allQuestions = this.allQuestions.sort((q1: QuestionModel, q2: QuestionModel) => parseFloat(q2.importance) - parseFloat(q1.importance));
                break;
            case 'lowest_importance_score':
                this.allQuestions = this.allQuestions.sort((q1: QuestionModel, q2: QuestionModel) => parseFloat(q1.importance) - parseFloat(q2.importance));
                break;
            case 'highest_negative_gap':
                this.allQuestions = this.allQuestions.sort((q1: QuestionModel, q2: QuestionModel) => parseFloat(q1.gap) - parseFloat(q2.gap));
                break;
            case 'highest_positive_gap':
                this.allQuestions = this.allQuestions.sort((q1: QuestionModel, q2: QuestionModel) => parseFloat(q2.gap) - parseFloat(q1.gap));
                break;
            case 'highest_standard_deviation':
                this.allQuestions = this.allQuestions.sort((q1: QuestionModel, q2: QuestionModel) => parseFloat(q1.score) - parseFloat(q2.score));
                break;
            case 'lowest_standard_deviation':
                this.allQuestions = this.allQuestions.sort((q1: QuestionModel, q2: QuestionModel) => parseFloat(q1.score) - parseFloat(q2.score));
                break;
        }
        return this.allQuestions;
    }
}

class CustomersListModal {
    id: number;
    icon_path: string;
    name: string;
    score: string | number;
    percentage: string | number;
    success: boolean;

    constructor(val) {
        this.id = val.id;
        this.icon_path = val.icon_path ? val.icon_path : Constants.BUSINESS_TMP_LOGO;
        this.name = val.name;
        this.score = val.stats.score;
        this.percentage = 4.5;
        this.success = true;
    }
}
