<template>
    <CustomLoader v-if="filterLoading" height="90vh"/>

    <div class="bank-dashboard product-area-overview platform-dashboard-pages">
        <template v-if="!filterLoading">
            <router-link class="back-previous-screen" to="/bank/product-areas">
                {{ "< " + $t("Back to [Previous Screen]", { previousScreen: $t("Product Areas") }) }}
            </router-link>

            <el-select
                v-model="productArea"
                class="dedicated-field-state"
                filterable
                :disabled="loading"
                @change="handleChangedProductAreas"
            >
                <el-option
                    v-for="(item, index) in bankProductAreas"
                    :key="index"
                    :label="item"
                    :value="item"
                />
            </el-select>

            <ProductAreaOverviewFilter
                ref="productAreaOverviewFilter"
                @apply-filters="applyDashboardFilters"
                @onChangedProductAreas="handleChangedProductAreas"
            />
        </template>

        <template v-if="!loading && !filterLoading">
            <ProductAreaOverviewInfo
                :overviewData="overviewData"
                :loading="loading"
            />
            <div class="d-flex gap-lg-8 customers-tables-container mb-10 w-100">

                <div class="card">
                    <CustomLoader height="100px" v-if="loading"/>

                    <template v-else>
                        <div class="d-flex align-items-center justify-content-between">
                            <span class="title">{{ $t('High 5 Customers') }}</span>
                            <!--            <a @click="onAllHighestScores">{{$t('All Highest Scored')}}</a>-->
                        </div>
                        <hr/>
                        <CustomersList
                            v-if="overviewData && overviewData.customersHighestList.length"
                            :data="overviewData.customersHighestList"/>
                        <div v-else class="no-data-found">{{ $t("No Data") }}</div>
                    </template>
                </div>
                <div class="card">
                    <CustomLoader height="100px" v-if="loading"/>

                    <template v-else>
                        <div class="d-flex align-items-center justify-content-between">
                            <span class="title">{{ $t('Low 5 Customers') }}</span>
                            <!--            <a @click="onAllLowestScores">{{$t('All Lowest Scored')}}</a>-->
                        </div>
                        <hr/>
                        <CustomersList
                            v-if="overviewData && overviewData.customersLowestList.length"
                            :data="overviewData.customersLowestList"/>
                        <div v-else class="no-data-found">{{ $t("No Data") }}</div>
                    </template>
                </div>
            </div>

            <QuestionsTabsContainer
                v-if="overviewData"
                :is-loading="loading"
                :benchmark-questions-list="overviewData.allQuestions"
                :custom-questions="overviewData.customQuestionsSectionData"
                @onClickQuestionItem="handleClickQuestionItem"
                @onSortingChange="overviewData.sortQuestionsBy($event)"
            />
        </template>

        <CustomLoader height="400px" v-else />
    </div>
</template>

<script>
import {setCurrentPageBreadcrumbs} from "@/core/helpers/breadcrumb";
import store from "@/store";
import ProductAreaOverviewInfo from "@/buying-teams/pages/bank/dashboard/sections/ProductAreaOverviewInfo";
import ProductAreaOverviewFilter from "@/buying-teams/pages/bank/dashboard/components/ProductAreaOverviewFilter";
import CustomerScores from "@/buying-teams/pages/bank/dashboard/sections/CustomerScores";
import CustomersList from "@/buying-teams/pages/bank/dashboard/components/CustomersList";
import CustomLoader from "@/buying-teams/shared-components/utils/CustomLoader";
import {BankFilterService} from "@/core/services/BankFilterService";
import {BankProductAreaOverviewContainer} from "@/store/models/bank/BankProductAreaOverviewContainer";
import {diagLog} from "@/core/helpers/GlobalHelper";
import BankFiltersTypeEnum from "@/store/enums/bank/BankFiltersTypeEnum";
import QuestionsTabsContainer from "@/buying-teams/shared-components/questions/QuestionsTabsContainer";
import {redirectToRouteWithName} from "@/buying-teams/helpers/UrlHelper";

export default {
    name: "BankDedicatedProductArea",
    components: {
        QuestionsTabsContainer,
        CustomLoader,
        CustomersList,
        CustomerScores,
        ProductAreaOverviewInfo,
        ProductAreaOverviewFilter,
    },
    data() {
        return {
            loading: true,
            filterLoading: true,
            overviewData: new BankProductAreaOverviewContainer({}),
            productArea: '',
        };
    },
    created() {
        setCurrentPageBreadcrumbs({
            title: this.bank.name,
            logo: this.bank.icon_path
        });
    },
    async mounted() {
        this.productArea = this.$route.params.productArea || "";
        if (this.productArea && this.productArea === '*') {
            let productArea = this.bankProductAreas[0] || '';
            if (!productArea) {
                redirectToRouteWithName("dashboard-bank-product-areas");
            }
            this.productArea = productArea;
            this.handleChangedProductAreas(this.productArea);
            return;
        }
        if (!this.productArea) {
            redirectToRouteWithName("dashboard-bank-product-areas");
        }

        this.initPage();
    },
    methods: {
        async initPage() {
            await store.dispatch('initFiltersData', {
                type: BankFiltersTypeEnum.PRODUCT_AREA_OVERVIEW,
                payload: { product_area: this.productArea },
                needSave: false,
            })
            this.filterLoading = false;
            this.fetchDashboardData(this.productAreaOverviewDashboardFilter);
        },
        handleConfirm(event) {
            this.dialogFormVisible = false;
        },
        async applyDashboardFilters(filters) {
            await this.fetchDashboardData(filters);
        },
        handleChangedProductAreas(productArea) {
            this.$router.replace({ name: "dashboard-product-area-overview", params: { productArea } });
            this.loading = true;
            this.filterLoading = true;
            this.initPage();
        },
        async fetchDashboardData(filters = null) {
            this.loading = true;
            store.dispatch('fetchProductAreaOverview', {
                product_area: this.productArea,
                filter: filters
            })
                .then(res => {
                    this.overviewData = new BankProductAreaOverviewContainer(res);
                })
                .finally(() => {
                    this.loading = false;
                })
        },
        onAllHighestScores() {
            diagLog("Show all highest scores!")
        },
        onAllLowestScores() {
            diagLog("Show all lowest scores!")
        },
        async handleClickQuestionItem(question, newTab) {
            this.overviewData.customQuestionsSectionData.redirectLoading = question.id;

            const filters = await store.dispatch('singleQuestionRedirectFilterState', {
                questionId: question.id,
                filterData: {
                    ...this.productAreaOverviewDashboardFilter,
                    product_areas: [this.productArea]
                },
            });

            store.commit('SAVE_BANK_SINGLE_QUESTION_FILTER', filters);
            this.overviewData.customQuestionsSectionData.redirectLoading = null;

            const questionUrl = `/bank/questions/${question.id}${question.isCustom ? '?isCustom=true' : ''}`;

            if (newTab) {
                window.open(questionUrl, '_blank');
            } else {
                this.$router.push(questionUrl);
            }
        }

    },
    computed: {
        bankProductAreas() {
            return store.getters.getBankProductAreas;
        },
        bank() {
            return store.getters.bank;
        },
        productAreaOverviewDashboardFilter() {
            return store.getters.productAreaOverviewDashboardFilter
        },
    },
    watch: {
        "$route.params": {
            handler() {
                const params = this.$route.params.productArea;
                if (params && params === '*' && this.productArea) {
                    this.$router.replace({ name: "dashboard-product-area-overview", params: { productArea: this.productArea } });
                }
            }
        }
    },
};
</script>

<style lang="scss">
.bank-dashboard.product-area-overview {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;

    .product-area-select {
        margin-top: 10px;

        .el-input__inner {
            padding-left: 10px;
            height: 33px;
            border-bottom: 2px solid #363636;
            border-radius: 0;
            font-weight: 700;
            font-size: 18px;
            line-height: 13px;
            color: rgba(0, 0, 0, 0.87);
            width: 276px;
        }

        .el-input__suffix svg path {
            fill: #000000;
        }
    }
    .card {
        background: #FFFFFF;
        box-shadow: 0 9px 15px rgba(0, 0, 0, 0.06);
        border-radius: 12px;
        flex: 1;

        .title {
            font-weight: 700;
            font-size: 18px;
            color: #000000;
            opacity: 0.87;
        }

        a {
            font-weight: 700;
            font-size: 14px;
            color: #435BF4;
            cursor: pointer;
        }
    }

    .custom-row {
        --bs-gutter-x: 2rem;
    }

    .bank-dashboard__customer-scores {
        flex: 1
    }

    @media (max-width: 1100px) {
        .customers-tables-container {
            flex-direction: column;
            gap: 24px;
        }
    }
    @media (max-width: 600px) {
        .customers-list {
            margin-left: -10px;
            margin-right: -10px;
        }
    }
}

.dashboard-section {
    &__title {
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        color: rgba(67, 67, 67, 0.6);
        padding-left: 9px;
        margin-bottom: 9px;

        &--count {
            margin-left: 8px;
            background: #FFFFFF;
            border-radius: 3px;
            font-weight: 600;
            font-size: 12px;
            line-height: 15px;
            text-align: center;
            color: #000000;
            padding: 2px 4px;
        }
    }

    .custom-tab-btn {
        display: flex;

        &__label {
            cursor: pointer;
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
            color: #8B8B8B;
            padding: 0 21px 8px 21px;
            border-bottom: 2px solid rgba(0, 0, 0, 0.03);

            &:hover {
                color: #435BF4;
            }

            &.active {
                border-color: rgba(67, 91, 244, 1);
                color: rgba(67, 91, 244, 1);
            }
        }
    }
}

.no-data-found {
    width: 100%;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    padding: 15px 0;
}
</style>
