<template>
  <div class="custom-count h-100">
    <div class="custom-count__image-wrapper">
      <img class="image w-100" :src="data.icon" alt="">
      <p class="count text-center pt-3 mb-0">{{ data.count }}</p>
      <div v-if="data.percent" class="text-center" :class="{'green-type': data.count > 200, 'red-type': data.count <= 200}">
<!--        <span>{{data.percent}}</span>-->
<!--        <svg width="7" height="8" viewBox="0 0 7 8" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--          <path-->
<!--            d="M2.83159 0.890084C3.12846 0.290198 3.98401 0.290199 4.28087 0.890085L6.82975 6.04066C7.09568 6.57804 6.70469 7.20778 6.1051 7.20778H1.00736C0.407773 7.20778 0.0167809 6.57804 0.282718 6.04066L2.83159 0.890084Z" />-->
<!--        </svg>-->
      </div>
    </div>
    <p class="title text-center">{{ $t(data.title) }}</p>
  </div>
</template>

<script>

export default {
  name: "CustomCount",
  props: { data: Object }
};

</script>

<style lang="scss">

.custom-count {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  max-width: fit-content;

  &__image-wrapper {
    position: relative;
    width: 80px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .image {
      position: absolute;
      filter: grayscale(1);
      opacity: 0.1;
    }
    .count {
      font-weight: 700;
      font-size: 36px;
      color: #000000;
      opacity: 0.87;
    }
    .green-type {
      span {
        color: #1B9F72;
      }

      svg {
        fill: #2BC490;
      }
    }

    .red-type {
      span {
        color: #E22D21FF;
      }

      svg {
        fill: #E22D21;
        transform: rotate(180deg);
      }
    }
  }
  .title {
    font-weight: 400;
    font-size: 14px;
    color: #717171;
    opacity: 0.87;
    white-space: nowrap;
    margin-bottom: 0;
  }
}
</style>

