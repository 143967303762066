<template>
  <div class="bank-dashboard__info dashboard-section mb-9">
    <div class="d-flex justify-content-between align-items-start align-items-md-center mb-2 flex-column flex-md-row mx-3">
      <p class="text-gray-600 mb-2 mb-md-0">
        {{ $t('Product Area Overview') }}
        <img src="/media/buying/icons/info_black.svg" alt="info_black">
      </p>
    </div>

    <div class="card">
      <CustomLoader height="270px" v-if="loading" />
      <template v-else>

        <div class="d-flex justify-content-between flex-wrap">
          <div class="your-banks-section__chart">
            <CircleChart
              :labelTop = "$t('Bank Score')"
              :labelBottom = "overviewData.productArea.titleBottom"
              :chartData="overviewData.productArea"
              :chartConfigs="{
              width: 400,
              height: 0.9 * 320,
              valueVisible: false
            }"
              :values="[overviewData.productArea.score, overviewData.productArea.importance]"
            />
          </div>
          <div class="your-banks-section">
            <div class="d-flex h-100 justify-content-around">
              <CustomCount :data="countsData.customers"/>
              <CustomCount :data="countsData.respondents"/>
            </div>

          </div>
          <div class="your-banks-section">
            <div class="d-flex h-100">
<!--              <ScoreImportanceTable-->
<!--                :followed="overviewData.followed"-->
<!--                :higest_gap="overviewData.high"-->
<!--                :lowest_gap="overviewData.low"-->
<!--              />-->
              <div class="followed-feature">
                <img src="/media/buying/icons/followed-feature.svg" alt="">
                <h4>{{ $t('Followed Portfolio Feature') }}</h4>
                <p>{{ $t('You will soon be able to follow specific companies and compare your portfolio against rest of the bank ') }}</p>
                <strong>{{ $t('Coming Soon') }}</strong>
              </div>
            </div>

          </div>
        </div>

      </template>
    </div>
  </div>
</template>

<script>
import CustomCount from "@/buying-teams/pages/bank/dashboard/components/CustomCount.vue";
// import ScoreImportanceTable from "@/buying-teams/pages/bank/dashboard/components/ScoreImportanceTable.vue";
import CustomLoader from "@/buying-teams/shared-components/utils/CustomLoader";
import CircleChart from "@/buying-teams/shared-components/charts/CircleChart";
import ScoreImportanceInfo from "@/buying-teams/shared-components/utils/ScoreImportanceInfo";

export default {
  name: "ProductAreaOverviewInfo",
  components: {
    ScoreImportanceInfo,
    CircleChart,
    CustomLoader,
    CustomCount,
    // ScoreImportanceTable
  },
  props: {
    loading: Boolean,
    overviewData: Object
  },
  computed: {
    countsData() {
      return {
        customers: {
          count: this.overviewData.customersCount,
          percent: 1.5,
          title: "Customers",
          icon: "/media/buying/icons/users.svg"
        },
        respondents :{
          count: this.overviewData.respondentsCount,
          percent: 4.5,
          title: "Respondents",
          icon: "/media/buying/icons/share-user.svg"
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.bank-dashboard {
  &__info {
      width: 100%;
    .card {
      padding-bottom: 20px;
      .your-banks-section {
        padding-left: 20px;
        border-left: 1px solid rgba(0, 0, 0, 0.1);
        flex:1
      }
    }
    &--bottom {
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      margin-top: 40px;
      padding: 20px 10px 0 10px;
      display: flex;
    }
  }
}

.followed-feature {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  img {
    margin-bottom: 4px;
  }
  h4 {
    font-weight: 700;
    font-size: 16px;
    line-height: 13px;
    text-align: center;
    color: #515151;
    margin-bottom: 8px;
  }
  p {
    font-weight: 400;
    font-size: 12px;
    line-height: 19px;
    text-align: center;
    color: rgba(113, 113, 113, 0.6);
    margin-bottom: 25px;
    max-width: 348px;
  }
  strong {
    font-weight: 500;
    font-size: 16px;
    line-height: 13px;
    text-align: center;
    color: #717171;
    text-transform: uppercase;
  }
}

@media (max-width: 1400px) {
  .bank-dashboard__info .card .your-banks-section {
    &:last-child {
      width: 100%;
      flex: revert;
      padding-left: 0;
      border-left: 0;
      padding-top: 20px;
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      margin-top: 20px;
    }
  }
}

@media (max-width: 1024px) {
  .bank-dashboard__info .card .your-banks-section {
    &:nth-child(2) {
      flex: revert;
      width: 100%;
      padding-left: 0;
      border-left: 0;
      padding-top: 20px;
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      margin-top: 20px;
    }
    &__chart {
      width: 100%;
    }
  }
}

@media (max-width: 600px) {
  .bank-dashboard__info .card .your-banks-section {
    &__chart {
      justify-content: center;
      display: flex;
      > .text-center {
        transform: scale(0.8);
      }
    }
  }
}
</style>
