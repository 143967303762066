<template>
  <div class="bank-dashboard__customer-scores dashboard-sections mb-9">
    <div class="dashboard-section__title d-flex justify-content-between align-items-center pe-3">
      <span>
        {{ $t('Customers') }}
        <span class="dashboard-section__title--count">{{ customersContainer.customersList ? customersContainer.customersList.length : 0 }}</span>
      </span>
      <router-link to="/bank/customers/all">{{ $t('View All Customers') }}</router-link>
    </div>
    <div class="card customer-scores-card" style="padding-bottom: 10px;">
      <div class="customer-scores-card__header">
        <h1>{{ $t('Sort Customers By') }}:</h1>
        <el-select v-model="customersSorting" placeholder="Select" size="large" class="sort-select">
          <el-option
            v-for="(item, index) in customersOptions"
            :key="index"
            :label="$t(item.label)"
            :value="item.key"
          />
        </el-select>
      </div>
      <div class="customer-scores-card__body">

        <CustomLoader v-if="isSorting"/>
        <div v-else>
          <CustomersList
            style="max-height: 845px;"
            :data="customersContainer.customersList"
            v-if="customersContainer.customersList && customersContainer.customersList.length"
          />
          <div v-else class="empty-list-state pt-20">
            <div class="empty-list-state__title">
              {{ $t('No Customer Added') }}
            </div>
            <img src="/media/buying/office-building.svg" alt="">
            <div class="empty-list-state__subtitle">
              {{ $t('You are yet to add your first customer.') }}
            </div>
            <div class="empty-list-state__text">
              {{ $t('Add users using the “Invite Customer” in your customer list') }}
            </div>
            <button class="btn btn-outline-primary">
              {{ $t('Invite Customers') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CustomersList from "@/buying-teams/pages/bank/dashboard/components/CustomersList";
import CustomLoader from "@/buying-teams/shared-components/utils/CustomLoader";
import {scoresSortingLevels} from "@/buying-teams/pages/bank/dashboard/dashboardData";
export default {
  name: "CustomerScores",
  components: { CustomLoader, CustomersList},
  props: {
    customersContainer: {
      type: Object
    }
  },
  watch: {
    customersSorting: {
      async handler(newVal) {
        this.isSorting = true;
        await this.customersContainer.sortCustomersBy(newVal);
        this.isSorting = false;
      }
    }
  },
  data() {
    return {
      customersSorting: 'highest_score',
      isSorting: false
    }
  },
  computed: {
    customersOptions() {
      return scoresSortingLevels;
    }
  },
  created() {
    this.customersContainer.sortCustomersBy(this.customersSorting);
  }
}
</script>

<style lang="scss">
.bank-dashboard__customer-scores {
  height: 100%;
}
.customer-scores-card {
  padding: 22px 0 0 0;
  height: 94%;
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px 20px 16px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);

    h1 {
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: #000000;
    }
    .el-select {
      width: 190px;

      .el-input {
        &__inner {
          height: 35px;
          padding-left: 20px;
          font-weight: 500;
          font-size: 12px;
          line-height: 15px;
          color: #313131;
        }
        &__suffix {
          right: 15px;
        }
      }
    }
  }
}
</style>
